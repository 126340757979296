import {
  MODULES,
  startTracking,
  stopTracking,
  initTracking,
  track
} from "@package/ipcmgr-toolkit";
import { func, object, shape } from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { configPropTypes } from "../config";
import { flagDefaults, initFlags } from "../utils/feature-flags";
import { logout, userPropTypes } from "../utils/user";
import SessionMonitor from "./SessionMonitor";

// Ability to override feature flags for testing purposes
const FLAG_OVERRIDE_URL_PARAM = "flag_overrides";

class UserProvider extends Component {
  static propTypes = {
    children: func.isRequired,
    config: shape(configPropTypes).isRequired,
    identifiedUser: shape(userPropTypes),
    renderLogin: func.isRequired,
    history: object.isRequired,
    location: object.isRequired
  };

  static defaultProps = {
    identifiedUser: null
  };

  constructor(props) {
    super(props);

    const { identifiedUser } = this.props;
    this.state = {
      user: identifiedUser && {
        ...identifiedUser,
        username: identifiedUser.id
      },
      flags: { ...flagDefaults, isReady: false, client: {} }
    };
  }

  componentDidMount() {
    const { history, location, config } = this.props;
    const { user } = this.state;

    const params = new URLSearchParams(location.search);
    if (params.has(FLAG_OVERRIDE_URL_PARAM)) {
      try {
        this.overrideFlags = JSON.parse(
          decodeURIComponent(params.get(FLAG_OVERRIDE_URL_PARAM))
        );
        // eslint-disable-next-line no-console
        console.log("Feature-flag overrides", this.overrideFlags);
        params.delete(FLAG_OVERRIDE_URL_PARAM);
        history.replace({ search: params.toString() });
      } catch (ex) {
        delete this.overrideFlags;
        // eslint-disable-next-line no-console
        console.error("Feature-flag overrides failed", ex);
      }
    }

    if (user) {
      this.initFlags(user);
      initTracking(config.mixpanelToken);
      startTracking(user);
    }
  }

  componentWillUnmount() {
    const { user } = this.state;
    if (user) {
      stopTracking();
    }
  }

  // on login, if there is no route, we can redirect to a default route
  defaultModuleRedirect = flags => {
    const {
      config: { defaultModule },
      history,
      location: { pathname }
    } = this.props;
    if (pathname === "/") {
      const newPath = defaultModule.find(moduleKey => {
        const module = MODULES.find(({ key }) => key === moduleKey);
        return module && flags[module.flag];
      });
      if (newPath) {
        history.replace(newPath);
      }
    }
  };

  setUser = ({ id, rights, roles, entitlements }) => {
    const user = {
      id,
      rights,
      entitlements,
      roles,
      username: id
    };
    this.setState({
      user
    });
    this.initFlags(user).then(this.defaultModuleRedirect);
    startTracking(user);
  };

  removeUser = async () => {
    const { config } = this.props;
    this.setState({
      flags: flagDefaults
    });
    await logout(config.services.url);
    if (this.cancelFlags) {
      this.cancelFlags();
    }
    stopTracking();
    window.location.reload();
  };

  updateFlags = (flags, client) => {
    // We want this exact object and don't want to lose the prototype getters
    // so mutate the object instead of replacing it.
    /* eslint-disable no-param-reassign */
    flags.isReady = true;
    flags.client = client;
    /* eslint-enable no-param-reassign */
    this.setState({
      flags
    });
  };

  initFlags(user) {
    const { config } = this.props;
    const { cancel, promise } = initFlags(config, user, this.overrideFlags);
    this.cancelFlags = cancel;
    return promise.then(({ flags, listen, client }) => {
      track("Logged In");
      this.updateFlags(flags, client);
      this.cancelFlags = listen(this.updateFlags);
      return flags;
    });
  }

  render() {
    const { children, config, renderLogin, location, history } = this.props;
    const { user, flags } = this.state;
    if (user) {
      return (
        <SessionMonitor
          authServiceUrl={config.services.url}
          logout={this.removeUser}
          userId={user.id}
        >
          {children(user, flags, this.removeUser)}
        </SessionMonitor>
      );
    }
    return renderLogin(this.setUser, location, history);
  }
}

export default withRouter(UserProvider);
