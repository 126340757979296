import React from "react";
import PropTypes from "prop-types";

const Notices = ({
  config: {
    services: { url }
  }
}) => <notices-component apiroot={url} parent="ipcmanager" />;

Notices.propTypes = {
  config: PropTypes.object.isRequired
};

export default Notices;
