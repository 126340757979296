import webComponents from "./webComponents";
export default (function (_a) {
    var webComponentsCdn = _a.webComponentsCdn;
    webComponents.forEach(function (id) {
        var componentExists = document.getElementById(id);
        if (!componentExists) {
            var cacheBust = Math.floor(1000000000000 + Math.random() * 9000000000000);
            var script = document.createElement("script");
            script.src = "".concat(webComponentsCdn, "/").concat(id, "/latest/").concat(id, ".umd.js?v=").concat(cacheBust);
            script.id = id;
            script.setAttribute("data-version", "latest");
            document.head.appendChild(script);
        }
    });
});
